<template>
  <v-container fluid>
    <v-row>
      <v-col lg="8" cols="12" class="mx-auto my-8">
        <v-card class="bg-white border-radius-xl px-4 pt-4">
          <div>
            <h5 class="font-weight-bolder text-h5 text-typo mb-0">
              {{ $t("Add User") }}
            </h5>
            <p class="mb-0 text-sm text-body">
              {{ $t("Mandatory information") }}
            </p>
            <v-row class="mt-2">
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1">{{
                  $t("Last Name")
                }}</label>
                <v-text-field
                  v-model="user_info.last_name"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="eg. Prior"
                  class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1">{{
                  $t("First Name")
                }}</label>
                <v-text-field
                  v-model="user_info.first_name"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="eg. Michael"
                  class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >UserName</label
                >
                <v-text-field
                  v-model="user_info.username"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="eg. Creative Tim"
                  class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Email Address</label
                >
                <v-text-field
                  v-model="user_info.email"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="eg. soft@dashboard.com"
                  class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Password</label
                >
                <v-text-field
                  v-model="user_info.password"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="******"
                  class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Repeat Password</label
                >
                <v-text-field
                  v-model="user_info.password2"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="******"
                  class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <!-- contacts -->
      <v-col lg="8" cols="12" class="mx-auto pt-0 pt-0">
        <v-card class="bg-white border-radius-xl px-4 pt-2">
          <div>
            <h5 class="font-weight-bolder text-h5 text-typo mb-7">
              {{ $t("Contacts") }}
            </h5>
            <v-row class="mt-2">
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >department</label
                >
                <v-text-field
                  v-model="user_info.department"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="Sales"
                  class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >job_title</label
                >
                <v-text-field
                  v-model="user_info.job_title"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="eg. Street 111"
                  class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >ext_phone</label
                >
                <v-text-field
                  v-model="user_info.ext_phone"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="eg. Street 111"
                  class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6" cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >mobile_phone</label
                >
                <v-text-field
                  v-model="user_info.mobile_phone"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="Sales"
                  class="font-size-input placeholder-lighter text-light-input border border-radius-md mt-2"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex">
            <v-btn
              :ripple="false"
              :elevation="0"
              class="font-weight-bold text-xs btn-light bg-gradient-light py-5 px-6 mt-6 mb-2 ms-2"
              @click="reset()"
            >
              Reset
            </v-btn>
            <v-btn
              :ripple="false"
              :elevation="0"
              class="font-weight-bold text-xs btn-default bg-gradient-default py-5 px-6 mt-6 mb-2 me-2 ms-auto"
              color="primary"
              @click="createUser()"
            >
              Submit
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

export default {
  name: "basic-info",
  data() {
    return {
      gender: ["Female", "Male"],
      user_info: {
        username: null,
        last_name: null,
        first_name: null,
        email: null,
        password: null,
        password2: null,
        company: null,
        department: null,
        ext_phone: null,
        mobile_phone: null,
        job_title: null,
      },
    };
  },
  methods: {
    ...mapGetters(["getToken"]),
    reset() {
      for (const property in this.user_info) {
        this.user_info[property] = null;
      }
    },
    createUser() {
      // TODO 移植notification (mixins)
      if (
        this.user_info.username == null ||
        this.user_info.password == null ||
        this.user_info.password2 == null
      ) {
        console.log("NO username or password");
        return;
      }
      if (this.user_info.password != this.user_info.password2) {
        console.log("Password not matched");
        return;
      }
      const token = this.getToken();
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/api/v1/users/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      let data = {};
      for (const property in this.user_info) {
        //  this.user_info[property] = null;
        if (this.user_info[property] != null) {
          data[property] = this.user_info[property];
        }
      }

      this.axios
        .post(url, data, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.showSuccessAlert(
            "Create user" + currentObj.username + " done."
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showSuccessAlert(message) {
      this.$swal("Good job!", message, "success");
    },
  },
};
</script>
