<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col>
        <user-profile></user-profile>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import UserProfile from "./Widgets/UserProfile.vue";

export default {
  name: "Settings",
  components: {
    UserProfile,
  },
  data() {
    return {};
  },
  methods: {
    ...mapGetters(["getToken"]),
    createUser() {
      console.log("create user.");
      if (
        this.username == null ||
        this.password == null ||
        this.password2 == null
      ) {
        console.log("NO username or password");
        return;
      }
      const token = this.getToken();
      const currentObj = this;
      let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      let data = {
        email: this.email,
        username: this.username,
        password: this.password,
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.showSuccessAlert(
            "Create user" + currentObj.username + " done."
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showSuccessAlert(message) {
      this.$swal("Good job!", message, "success");
    },
  },
};
</script>
